import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import vendorService from '../../services/vendor.service';

const initialState = {
  loading: false,
  payload: null,
  error: null,
  profilePicture: null,
};

export const getVendor = createAsyncThunk('vendor/get_vendor', async (_, { rejectWithValue }) => {
  try {
    return await vendorService.getVendor();
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateVendor = createAsyncThunk('vendor/update_vendor', async (data, { rejectWithValue }) => {
  try {
    return await vendorService.updateVendor(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const listProducts = createAsyncThunk('vendor/list_products', async (_, { rejectWithValue }) => {
  try {
    return await vendorService.listProducts();
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getProduct = createAsyncThunk('vendor/get_product', async (data, { rejectWithValue }) => {
  try {
    return await vendorService.getProduct(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const createProduct = createAsyncThunk('vendor/create_product', async (_, { rejectWithValue }) => {
  try {
    return await vendorService.createProduct();
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateProduct = createAsyncThunk('vendor/update_product', async (data, { rejectWithValue }) => {
  try {
    return await vendorService.updateProduct(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const deleteProduct = createAsyncThunk('vendor/get_product', async (data, { rejectWithValue }) => {
  try {
    return await vendorService.deleteProduct(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const listOrders = createAsyncThunk('vendor/list_orders', async (data, { rejectWithValue }) => {
  try {
    return await vendorService.listOrders(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getOrder = createAsyncThunk('vendor/get_order', async (data, { rejectWithValue }) => {
  try {
    return await vendorService.getOrder(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateOrder = createAsyncThunk('vendor/update_order', async (data, { rejectWithValue }) => {
  try {
    return await vendorService.updateOrder(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getStripeAccountLink = createAsyncThunk('vendor/getStripeAccountLink', async (data, { rejectWithValue }) => {
  try {
    return await vendorService.getStripeAccountLink(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const listScheduleClose = createAsyncThunk('vendor/list_schedule-close', async (data, { rejectWithValue }) => {
  try {
    return await vendorService.listScheduleClose(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const createUpdateScheduleClose = createAsyncThunk('vendor/create-update_schedule-close', async (data, { rejectWithValue }) => {
  try {
    return await vendorService.createUpdateScheduleClose(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

const { actions, reducer } = createSlice({
  name: 'vendor',
  initialState,
  reducers: {
    saveProfilePicture: (state, action) => {
      state.profilePicture = action.payload;
    },
  },
  extraReducers: {
    /** Get vendor reducer **/
    [getVendor.fulfilled]: (state, { meta, payload }) => {
      sessionStorage.setItem('vendor', JSON.stringify(payload.data));
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [getVendor.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [getVendor.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** Update vendor reducer **/
    [updateVendor.fulfilled]: (state, { meta, payload }) => {
      sessionStorage.setItem('vendor', JSON.stringify(payload.data));
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [updateVendor.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [updateVendor.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** List products reducer **/
    [listProducts.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [listProducts.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [listProducts.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** Get a product reducer **/
    [getProduct.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [getProduct.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [getProduct.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** Create a product reducer **/
    [createProduct.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [createProduct.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [createProduct.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** Update a product reducer **/
    [updateProduct.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [updateProduct.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [updateProduct.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** Delete a product reducer **/
    [deleteProduct.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [deleteProduct.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [deleteProduct.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** List orders reducer **/
    [listOrders.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [listOrders.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [listOrders.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** Get order reducer **/
    [getOrder.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [getOrder.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [getOrder.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** Update order reducer **/
    [updateOrder.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [updateOrder.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [updateOrder.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** Get Stripe account link reducer **/
    [getStripeAccountLink.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [getStripeAccountLink.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [getStripeAccountLink.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
  },
});

export const { saveProfilePicture } = actions;
export default reducer;
