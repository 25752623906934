import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment';
import Layout from '../../components/Layout';
import { Form, Input } from '../../components/Form';

import { chunkingOperationHours, classNames, getAddressGeocode } from '../../utils';

import { showToast } from '../../store/slices/components.slice';
import { getPrivateFile } from '../../store/slices/file.slice';
import { getStripeAccountLink, getVendor, updateVendor } from '../../store/slices/vendor.slice';
import tokenService from '../../services/token.service';
import Tooltip from '../../components/Tooltip';

const Register = () => {
  const formNode = useRef();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState(location.state);
  const [formErrors, setFormErrors] = useState([]);
  const { vendorApi, fileApi } = useSelector((state) => state);

  useEffect(() => {
    getInitialData(setCurrentStep);
  }, []);

  const updateFormData = (data) => {
    setFormData({ ...formNode.current.getFormData(), ...data });
    setTriggerUpdate(!triggerUpdate);
  };

  const steps = [
    {
      id: 0,
      title: 'Store details',
      panel: StoreDetailsForm({ updateFormData, formNode }),
      validate: [
        'storeName',
        'storeDescription',
        'storeProfilePic',
        'storeBannerPic',
        'storeAddress1',
        'storeCity',
        'storeState',
        'storePostalCode',
        'storeLocationLatLong.lat',
        'storeLocationLatLong.lon',
        'totalLoadCapacity',
        'adminName',
        'adminContactNumber',
      ],
    },
    {
      id: 1,
      title: 'Company details',
      panel: CompanyDetailsForm(),
      validate: [],
    },
    {
      id: 2,
      title: 'Stripe account',
      panel: StripeAccount(formData),
    },
    {
      id: 3,
      title: 'Review & submit',
      panel: ReviewAndSubmit(formData),
    },
  ];

  const getInitialData = (setCurrentStep) => {
    setLoading(true);
    if (tokenService.getLocalAccessToken()) {
      dispatch(getVendor())
        .unwrap()
        .then(({ data }) => {
          setLoading(false);
          if (['REGISTER', 'PENDING'].includes(data.status)) {
            history.push({ state: data });
            setFormData(data);
            if (data.status === 'PENDING') {
              setCurrentStep(3);
            } else if (data.storeDescription) {
              setCurrentStep(2);
            }
          } else {
            dispatch(showToast({ show: true, type: 'warning', message: 'Your account have been activated' }));
            history.push('/store');
          }
        })
        .catch(({ message }) => {
          setLoading(false);
          history.push('/');
        });
    }
  };

  const handleSubmit = (data) => {
    /** Change status to SUBMIT and delete reserved params from data payload */
    if (formData?.status === 'PENDING') {
      data.action = 'SAVE';
    } else {
      data.action = 'SUBMIT';
    }

    delete data.storeLocationAddressHiddenInput;
    delete data.stripeAccountId;
    delete data.stripeAccount;

    dispatch(updateVendor(data))
      .unwrap()
      .then((result) => {
        dispatch(showToast({ show: true, type: 'success', message: `Your registration has been successfully sent!` }));
        history.push('/');
      })
      .catch(({ message, validate }) => {
        dispatch(showToast({ show: true, type: 'error', message: message, errors: validate?.details }));
        setFormErrors(validate?.details);
        setTimeout(() => {
          const element = document.getElementById('rule-error');
          element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 100);
      });
  };

  const goToStep = async (step) => {
    if (step < steps.length) {
      if (currentStep < step) {
        const canProceed = await formNode.current.validateForm(steps[step - 1].validate);
        if (canProceed) {
          let resp = await handleSave(formNode.current.getFormData());
          if (resp) setCurrentStep(step);
        }
      } else {
        let resp = await handleSave(formNode.current.getFormData());
        if (resp) setCurrentStep(step);
      }
    }
  };

  const handleSave = (data) => {
    let newData = {
      ...data,
    };

    setFormData(newData);
    history.replace({ ...history, state: newData });

    /** Change action to SAVE and delete reserved params from data payload */
    data.action = 'SAVE';
    delete data.status;
    delete data.userUid;
    delete data.uid;
    delete data.storeLocationGeoHash;
    delete data.storeLocationAddressHiddenInput;
    delete data.stripeAccountId;
    delete data.stripeAccount;

    return dispatch(updateVendor(data))
      .unwrap()
      .then(({ data }) => {
        /** Replace current data payload into history state and form data */
        setFormErrors([]);
        return true;
      })
      .catch(({ message, validate }) => {
        dispatch(showToast({ show: true, type: 'error', message: message, errors: validate?.details }));
        setFormErrors(validate?.details);
        setTimeout(() => {
          const element = document.getElementById('rule-error');
          element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 100);
        return false;
      });
  };

  return (
    <>
      <div className="max-w-7xl mx-auto px-6 md:px-8 py-10">
        <div className="mb-8">
          <div className="flex items-center justify-start md:justify-evenly w-full">
            {steps.map((step) => (
              <Fragment key={step.id}>
                <div className={classNames(step.id === currentStep ? 'block' : 'hidden sm:block', 'w-full md:w-auto')}>
                  <div className={classNames(currentStep >= step.id ? 'bg-primary-500 ' : 'bg-gray-300', 'h-1 w-full md:w-60')}></div>
                  <div className="mt-3">
                    <span className={classNames(currentStep >= step.id ? 'text-primary-600' : 'text-gray-500', 'uppercase text-xs font-semibold')}>Step {step.id + 1}</span>
                    <p className={classNames(currentStep >= step.id ? 'text-gray-900' : 'text-gray-400', 'text-sm font-medium')}>
                      {step.title}
                      <span className={classNames(step.id == 1 ? 'text-gray-400' : 'text-gray-100', 'text-sm font-medium')}> (Optional) </span>
                    </p>
                  </div>
                </div>
                <svg
                  className={classNames(
                    step.id + 1 === steps.length && 'sr-only',
                    currentStep >= step.id + 1 ? 'text-primary-600' : 'text-gray-400',
                    'hidden md:block w-8 h-8 flex-shrink-0'
                  )}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                </svg>
              </Fragment>
            ))}
          </div>
        </div>
        {formData?.status === 'PENDING' && (
          <div className="bg-green-400 bg-opacity-40 border-2 mb-5 rounded-md font-bold text-center py-2">Your application submitted, pending verification</div>
        )}
        <div className="bg-white ring-1 ring-black ring-opacity-5 rounded-lg shadow-md overflow-hidden">
          <div>
            <div>
              <div>
                <Form onSubmit={handleSubmit} defaultValues={formData} setRef={formNode} setErrors={formErrors} triggerUpdateData={triggerUpdate}>
                  {steps.map((step) => (
                    <div key={step.id} className={`${currentStep !== step.id ? 'hidden' : 'block'}`}>
                      {step.panel}
                    </div>
                  ))}
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 border-t">
                    <div className={classNames(currentStep === 0 ? 'justify-end' : 'justify-between', 'flex items-center')}>
                      <button
                        type="button"
                        onClick={() => goToStep(currentStep - 1)}
                        disabled={vendorApi.loading || fileApi.loading}
                        className={classNames(
                          currentStep === 0 ? 'hidden' : 'block',
                          'mr-4 inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
                        )}
                      >
                        {vendorApi.loading || fileApi.loading ? (
                          <svg className="animate-spin h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        ) : (
                          'Previous'
                        )}
                      </button>
                      <button
                        type="button"
                        onClick={() => goToStep(currentStep + 1)}
                        disabled={vendorApi.loading || fileApi.loading || currentStep === 2 ? (formData?.stripeAccount?.payouts_enabled ? false : true) : false}
                        className={classNames(
                          currentStep !== steps.length - 1 ? 'inline-flex' : 'hidden',
                          'justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-primary-100 bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:bg-primary-300'
                        )}
                      >
                        {vendorApi.loading || fileApi.loading ? (
                          <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        ) : (
                          'Save & Next'
                        )}
                      </button>
                      {formData?.status === 'PENDING' ? (
                        <button
                          type="button"
                          disabled={formData?.status}
                          className={classNames(
                            currentStep === steps.length - 1 ? 'inline-flex' : 'hidden',
                            'justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md bg-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
                          )}
                        >
                          Submitted
                        </button>
                      ) : (
                        <button
                          type="submit"
                          disabled={vendorApi.loading || fileApi.loading}
                          className={classNames(
                            currentStep === steps.length - 1 ? 'inline-flex' : 'hidden',
                            'justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-primary-100 bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
                          )}
                        >
                          {vendorApi.loading || fileApi.loading ? (
                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          ) : (
                            'Submit'
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const StoreDetailsForm = ({ updateFormData, formNode }) => {
  const [selectedDay, setSelectedDay] = useState('sunday');
  const { vendorApi } = useSelector((state) => state);
  const [formData, setFormData] = useState(null);
  const [tooltipLocation, setTooltipLocation] = useState(false);

  useEffect(() => {
    setFormData(formNode.current.getFormData());
  }, []);

  const dispatch = useDispatch();

  const days = [
    { name: 'Sun', value: 'sunday' },
    { name: 'Mon', value: 'monday' },
    { name: 'Tue', value: 'tuesday' },
    { name: 'Wed', value: 'wednesday' },
    { name: 'Thu', value: 'thursday' },
    { name: 'Fri', value: 'friday' },
    { name: 'Sat', value: 'saturday' },
  ];

  const mapRef = useRef();

  const handleStoreAddress = async () => {
    let currentFormData = formNode.current.getFormData();

    let addressObj = {
      storeAddress1: currentFormData['storeAddress1'],
      storeAddress2: currentFormData['storeAddress2'] || '',
      storeCity: currentFormData['storeCity'],
    };

    if (mapRef) {
      const geocoder = new mapRef.current.map.maps_.Geocoder();
      let { state, postalCode, coordinate } = await getAddressGeocode(geocoder, addressObj);
      updateFormData({
        storeState: state || '',
        storePostalCode: postalCode || '',
        storeLocationLatLong: coordinate || {},
      });
      if (!coordinate) {
        setTooltipLocation(true);
      } else {
        setTooltipLocation(false);
      }
    }
  };

  return (
    <>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">
                <span className="text-red-500 mr-1">*</span>
                Store profile photo
              </h3>
              <p className="text-sm font-medium text-gray-500">Image that represents your store.</p>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2">
            <Input.Avatar
              name="storeProfilePic"
              apiParams={{ object: 'vendor.storeProfilePic' }}
              disabled={vendorApi.loading}
              rules={[{ required: true, message: 'This field is required' }]}
            />
          </div>
        </div>
      </div>

      <div className="block" aria-hidden="true">
        <div className="border-t border-gray-200"></div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">
                <span className="text-red-500 mr-1">*</span>
                Store banner photo
              </h3>
              <p className="text-sm font-medium text-gray-500">Image that represents your products.</p>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2">
            <Input.File
              name="storeBannerPic"
              apiParams={{ object: 'vendor.storeBannerPic' }}
              withPhotoCropper={true}
              aspectRatio={4 / 1}
              imageDimension="w-[600px] h-[150px]"
              disabled={vendorApi.loading}
              accept=".jpeg,.jpg,.png,.webp,.gif,.svg"
              rules={[{ required: true, message: 'This field is required' }]}
            />
          </div>
        </div>
      </div>

      <div className="block" aria-hidden="true">
        <div className="border-t border-gray-200"></div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">
                <span className="text-red-500 mr-1">*</span>
                Store name
              </h3>
              <p className="text-sm font-medium text-gray-500">Name that distinguish your store.</p>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2">
            <Input.Text name="storeName" placeholder="Your store name" disabled={vendorApi.loading} rules={[{ required: true, message: 'This field is required' }]} />
          </div>
        </div>
      </div>

      <div className="block" aria-hidden="true">
        <div className="border-t border-gray-200"></div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">
                <span className="text-red-500 mr-1">*</span>
                Store description
              </h3>
              <p className="text-sm font-medium text-gray-500">Brief summary of what your store is selling.</p>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2">
            <Input.Textarea
              name="storeDescription"
              placeholder="Your store description"
              disabled={vendorApi.loading}
              rules={[{ required: true, message: 'This field is required' }]}
            />
          </div>
        </div>
      </div>

      <div className="block" aria-hidden="true">
        <div className="border-t border-gray-200"></div>
      </div>

      <div>
        <Input.GoogleMap setRef={mapRef} className="sr-only" name="storeLocationAddressHiddenInput" fieldType="address" />
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">
                <span className="text-red-500 mr-1">*</span>
                Store address
              </h3>
              <p className="text-sm font-medium text-gray-500">Where your store is located?</p>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2 grid grid-cols-1 md:grid-cols-6 gap-6">
            <div className="col-span-1 md:col-span-3">
              <Input.Text name="storeAddress1" placeholder="Address Line 1" disabled={vendorApi.loading} rules={[{ required: true, message: 'This field is required' }]} />
            </div>
            <div className="col-span-1 md:col-span-3">
              <Input.Text name="storeAddress2" placeholder="Address Line 2" disabled={vendorApi.loading} />
            </div>
            <div className="col-span-1 md:col-span-2">
              <Input.Text name="storeCity" placeholder="City" disabled={vendorApi.loading} rules={[{ required: true, message: 'This field is required' }]} />
            </div>
            <div className="col-span-1 md:col-span-2">
              <Input.Select
                name="storeState"
                placeholder="Select a state"
                options={[
                  'Perlis',
                  'Kedah',
                  'Perak',
                  'Selangor',
                  'Kuala Lumpur',
                  'Negeri Sembilan',
                  'Melaka',
                  'Johor',
                  'Kelantan',
                  'Terengganu',
                  'Pahang',
                  'Putrajaya',
                  'Labuan',
                  'Sabah',
                  'Sarawak',
                ]}
                disabled={vendorApi.loading}
                rules={[{ required: true, message: 'This field is required' }]}
              />
            </div>
            <div className="col-span-1 md:col-span-2">
              <Input.Text name="storePostalCode" placeholder="Postal code" disabled={vendorApi.loading} rules={[{ required: true, message: 'This field is required' }]} />
            </div>
          </div>
        </div>
      </div>

      <div className="block" aria-hidden="true">
        <div className="border-t border-gray-200"></div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">
                <span className="text-red-500 mr-1">*</span>
                Store location
              </h3>
              <p className="text-sm font-medium text-gray-500">Location of store by coordinates.</p>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2">
            {tooltipLocation && (
              <Tooltip from="top" bgColor="bg-red-600/95 text-white" chevronColor="text-amber-500">
                Cannot find location from your address. Please check your address again.
              </Tooltip>
            )}
            <button
              type="button"
              onClick={() => handleStoreAddress()}
              className="text-sm mb-4 font-medium text-primary-500 hover:text-primary-600 border-primary-600 border-b border-dotted"
            >
              Get store address coordinate
            </button>
            <Input.GoogleMap
              name="storeLocationLatLong"
              hint="Insert the latitude and longitude"
              hideCurrentLocationButton
              disabled={vendorApi.loading}
              rules={[{ required: true, message: 'This field is required' }]}
            />
          </div>
        </div>
      </div>

      <div className="block" aria-hidden="true">
        <div className="border-t border-gray-200"></div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">
                <span className="text-red-500 mr-1">*</span>
                Total load capacity
              </h3>
              <p className="text-sm font-medium text-gray-500">Total number of products you make per day.</p>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2">
            <Input.Number
              type="Integer"
              name="totalLoadCapacity"
              placeholder="e.g 100"
              disabled={vendorApi.loading}
              rules={[{ required: true, message: 'This field is required' }]}
            />
          </div>
        </div>
      </div>

      <div className="block" aria-hidden="true">
        <div className="border-t border-gray-200"></div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">
                <span className="text-red-500 mr-1">*</span>
                Availability distance (KM)
              </h3>
              <p className="text-sm font-medium text-gray-500">Distance available for your orders to be delivered.</p>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2">
            <Input.Number
              type="Integer"
              name="availabilityRadiusKm"
              placeholder="e.g 10"
              disabled={vendorApi.loading}
              rules={[{ required: true, message: 'This field is required' }]}
            />
          </div>
        </div>
      </div>

      <div className="block" aria-hidden="true">
        <div className="border-t border-gray-200"></div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">
                <span className="text-red-500 mr-1">*</span>
                Operation hours
              </h3>
              <p className="text-sm font-medium text-gray-500">Available delivery time for your orders.</p>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2">
            <div className="w-full px-2 sm:px-0">
              <div className="flex p-1 space-x-1 bg-gray-200/40 rounded-xl">
                {days.map((day) => (
                  <button
                    type="button"
                    key={day.value}
                    onClick={() => setSelectedDay(day.value)}
                    className={classNames(
                      'w-full py-1.5 text-sm leading-5 font-medium text-primary-500 rounded-lg capitalize',
                      'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-primary-400 ring-white ring-opacity-60',
                      selectedDay === day.value ? 'bg-white shadow' : 'text-gray-500 hover:text-primary-500'
                    )}
                  >
                    {day.name}
                  </button>
                ))}
              </div>
              <div className="mt-4">
                {days.map((day, idx) => (
                  <div
                    key={idx}
                    className={classNames('bg-white rounded-xl p-3 ring-1 ring-black ring-opacity-5 focus:outline-none shadow', selectedDay !== day.value ? 'hidden' : '')}
                  >
                    <Input.PresetTimePicker
                      name={`storeOperationSchedules.${day.value}`}
                      times={['08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00']}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="block" aria-hidden="true">
        <div className="border-t border-gray-200"></div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">
                <span className="text-red-500 mr-1">*</span>
                Admin name
              </h3>
              <p className="text-sm font-medium text-gray-500">Full name of person managing the store.</p>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2">
            <Input.Text name="adminName" placeholder="Your admin name" disabled={vendorApi.loading} rules={[{ required: true, message: 'This field is required' }]} />
          </div>
        </div>
      </div>

      <div className="block" aria-hidden="true">
        <div className="border-t border-gray-200"></div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">
                <span className="text-red-500 mr-1">*</span>
                Admin contact number
              </h3>
              <p className="text-sm font-medium text-gray-500">Contact number of person managing the store.</p>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2">
            <Input.Phone
              name="adminContactNumber"
              placeholder="e.g 60123456789"
              disabled={vendorApi.loading}
              rules={[
                { required: true, message: 'This field is required' },
                { pattern: /^((\+6|6)?01)[0-9]{8,9}$/, message: 'Wrong phone format' },
              ]}
            />
          </div>
        </div>
      </div>

      <div className="block" aria-hidden="true">
        <div className="border-t border-gray-200"></div>
      </div>
    </>
  );
};

const CompanyDetailsForm = () => {
  const { vendorApi } = useSelector((state) => state);

  return (
    <>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-2xl  font-medium leading-6 text-gray-700">(Optional)</h3>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">Company name</h3>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2">
            <Input.Text name="companyName" placeholder="Your company name" disabled={vendorApi.loading} />
          </div>
        </div>
      </div>

      <div className="block" aria-hidden="true">
        <div className="border-t border-gray-200"></div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">Company registration number</h3>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2">
            <Input.Text name="companyNumber" placeholder="Your company registration number" disabled={vendorApi.loading} />
          </div>
        </div>
      </div>

      <div className="block" aria-hidden="true">
        <div className="border-t border-gray-200"></div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">Company SSM certificate</h3>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2">
            <Input.File name="companySSMCert" apiParams={{ object: 'vendor.companySSMCert' }} disabled={vendorApi.loading} maxFiles={2} accept=".pdf,.jpeg,.jpg,.png" />
          </div>
        </div>
      </div>
    </>
  );
};

const StripeAccount = (data) => {
  const dispatch = useDispatch();

  const handleGetStripeLink = async () => {
    dispatch(getStripeAccountLink({ returnUrl: window.location.href }))
      .unwrap()
      .then(({ data }) => {
        const { created, expires_at, object, url } = data.accountLink;
        window.location.replace(url);
      });
  };

  return (
    <>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">Stripe account</h3>
              <p className="text-sm font-medium text-gray-500">Payment Gateway account.</p>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2">
            <div className="w-full px-2 sm:px-0">
              {!data?.stripeAccount?.id && <p className="text-primary-600">Please click here to create a stripe account.</p>}
              <button
                type="button"
                onClick={handleGetStripeLink}
                className="mb-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:w-auto sm:text-sm"
              >
                {data?.stripeAccount?.id ? 'Update Stripe account' : 'Create Stripe account'}
              </button>
              {data?.stripeAccount?.requirements?.errors?.length > 0 && (
                <div>
                  <div className="py-2 flex justify-between">
                    <p className="text-sm font-medium text-red-600">
                      <b>Stripe message:</b> {data?.stripeAccount?.requirements?.errors[0]?.reason}
                      <br />
                    </p>
                  </div>
                  <div className="py-2 flex justify-between">
                    <p className="text-sm font-medium text-gray-700">
                      Please click "<b>Update Stripe account</b>" button for updating your Stripe account
                    </p>
                  </div>
                </div>
              )}
              {data?.stripeAccount?.payouts_enabled !== true && data?.stripeAccountId && (
                <div>
                  <div className="py-2 flex justify-between">
                    <p className="text-sm font-medium text-orange-600">
                      Please wait until your Stripe Account is approved and validated by Stripe to be able to proceed next step. It will take 0 to 3 working days
                    </p>
                  </div>
                </div>
              )}
              {data?.stripeAccount?.payouts_enabled == true && (
                <div>
                  <div className="py-2 flex justify-between">
                    <p className="text-sm flex font-medium text-orange-600">
                      <b>Congratulations</b>&nbsp; your stripe account has been approved you can proceed to next step
                    </p>
                  </div>
                </div>
              )}
              <div className="divide-y">
                <div className="pb-2 flex justify-between">
                  <p className="text-sm font-medium text-gray-500">ID</p>
                  <p className="text-sm font-medium text-gray-900">{data?.stripeAccountId}</p>
                </div>
                <div className="py-2 w-full">
                  <p className="text-sm font-medium text-gray-500">Business profile</p>
                  <table className="mt-2 w-full">
                    <tbody>
                      <tr>
                        <td>MCC</td>
                        <td className="text-sm font-medium text-gray-900 text-right">{data?.stripeAccount?.business_profile?.mcc || 'N/A'}</td>
                      </tr>
                      <tr>
                        <td>Name</td>
                        <td className="text-sm font-medium text-gray-900 text-right">{data?.stripeAccount?.business_profile?.name || 'N/A'}</td>
                      </tr>
                      <tr>
                        <td>Support Address</td>
                        <td className="text-sm font-medium text-gray-900 text-right">{data?.stripeAccount?.business_profile?.support_address || 'N/A'}</td>
                      </tr>
                      <tr>
                        <td>Support Email</td>
                        <td className="text-sm font-medium text-gray-900 text-right">{data?.stripeAccount?.business_profile?.support_email || 'N/A'}</td>
                      </tr>
                      <tr>
                        <td>Support Phone</td>
                        <td className="text-sm font-medium text-gray-900 text-right">{data?.stripeAccount?.business_profile?.support_phone || 'N/A'}</td>
                      </tr>
                      <tr>
                        <td>Support URL</td>
                        <td className="text-sm font-medium text-gray-900 text-right">{data?.stripeAccount?.business_profile?.support_url || 'N/A'}</td>
                      </tr>
                      <tr>
                        <td>URL</td>
                        <td className="text-sm font-medium text-gray-900 text-right">{data?.stripeAccount?.business_profile?.url || 'N/A'}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="py-2 flex justify-between">
                  <p className="text-sm font-medium text-gray-500">Account completed?</p>
                  {data?.stripeAccountId == null && <p className="text-sm font-medium text-gray-900">No</p>}
                  {data?.stripeAccountId && <p className="text-sm font-medium text-gray-900">{data?.stripeAccount?.payouts_enabled ? 'Yes' : 'No (Stripe Pending)'}</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ReviewAndSubmit = (data) => {
  const dispatch = useDispatch();

  const chunkedOperationHours =
    data &&
    Object.keys(data.storeOperationSchedules).reduce((acc, key) => {
      acc[key] = chunkingOperationHours(data?.storeOperationSchedules[key]);
      return acc;
    }, {});

  const handleGetPrivateFile = (file_path) => {
    dispatch(getPrivateFile(file_path))
      .unwrap()
      .then(async (result) => {
        const { signUrl } = result.data;
        const win = window.open(signUrl, '_blank');
        win.focus();
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  };

  const days = [
    { name: 'Sun', value: 'sunday' },
    { name: 'Mon', value: 'monday' },
    { name: 'Tue', value: 'tuesday' },
    { name: 'Wed', value: 'wednesday' },
    { name: 'Thu', value: 'thursday' },
    { name: 'Fri', value: 'friday' },
    { name: 'Sat', value: 'saturday' },
  ];

  return (
    <>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">Store name</h3>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2">{data?.storeName}</div>
        </div>
      </div>

      <div className="block" aria-hidden="true">
        <div className="border-t border-gray-200"></div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">Store description</h3>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2">{data?.storeDescription}</div>
        </div>
      </div>

      <div className="block" aria-hidden="true">
        <div className="border-t border-gray-200"></div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">Store address</h3>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2">
            {`${data?.storeAddress1}, ${data?.storeAddress2 ? `${data?.storeAddress2}, ` : ''} ${data?.storePostalCode} ${data?.storeCity}, ${data?.storeState}`}
          </div>
        </div>
      </div>

      <div className="block" aria-hidden="true">
        <div className="border-t border-gray-200"></div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">Store location</h3>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2">{`${data?.storeLocationLatLong?.lat?.toFixed(4)}, ${data?.storeLocationLatLong?.lon?.toFixed(4)}`}</div>
        </div>
      </div>

      <div className="block" aria-hidden="true">
        <div className="border-t border-gray-200"></div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">Total load capacity</h3>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2">{data?.totalLoadCapacity}</div>
        </div>
      </div>

      <div className="block" aria-hidden="true">
        <div className="border-t border-gray-200"></div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">Availability distance (KM)</h3>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2">{data?.availabilityRadiusKm}</div>
        </div>
      </div>

      <div className="block" aria-hidden="true">
        <div className="border-t border-gray-200"></div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">Operation hours</h3>
            </div>
          </div>
          <div className="mt-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2 ring-1 ring-black ring-opacity-5 shadow rounded-md overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th scope="col" className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Day
                  </th>
                  <th scope="col" className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Time
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y">
                {days.map((day, index) => (
                  <tr key={index}>
                    <td className="px-6 py-3 whitespace-nowrap text-left text-sm">{day.name}</td>
                    {data && data.storeOperationSchedules[day.value].length > 0 ? (
                      <td className="px-6 py-3 whitespace-nowrap text-left text-sm">
                        {chunkedOperationHours[day.value].map((hours, index) => {
                          if (hours.length > 1)
                            return <p key={index}>{`${moment(hours[0], 'HH:mm:ss').format('hh:mm A')} - ${moment(hours[hours.length - 1], 'HH:mm:ss').format('hh:mm A')}`}</p>;
                          else return <p key={index}>{`${moment(hours[0], 'HH:mm:ss').format('hh:mm A')}`}</p>;
                        })}
                      </td>
                    ) : (
                      <td className="px-6 py-3 whitespace-nowrap text-left text-sm">Closed</td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="block" aria-hidden="true">
        <div className="border-t border-gray-200"></div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">Company name</h3>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2">{data?.companyName || 'Not provided'}</div>
        </div>
      </div>

      <div className="block" aria-hidden="true">
        <div className="border-t border-gray-200"></div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">Company registration number</h3>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2">{data?.companyNumber || 'Not provided'}</div>
        </div>
      </div>

      <div className="block" aria-hidden="true">
        <div className="border-t border-gray-200"></div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">Company SSM certificate</h3>
            </div>
          </div>
          {data?.companySSMCert ? (
            <dd className="mt-2 md:mt-0 md:col-span-2">
              <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div className="w-0 flex-1 flex items-center">
                    <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path
                        fillRule="evenodd"
                        d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <button
                      type="button"
                      onClick={() => handleGetPrivateFile(data?.companySSMCert?.path)}
                      className="ml-2 truncate text-primary-600 dark:text-primary-500 hover:text-primary-700 dark:hover:text-primary-600 font-medium border-b border-dotted border-primary-600 dark:border-primary-500 hover:border-primary-700 dark:hover:border-primary-600"
                    >
                      {`${data?.companySSMCert?.path?.split('/')[2]}.${data?.companySSMCert?.path?.split('/')[3].split('.')[1]}`}
                    </button>
                  </div>
                </li>
              </ul>
            </dd>
          ) : (
            <dd className="mt-2 md:mt-0 md:col-span-2">Not provided</dd>
          )}
        </div>
      </div>

      <div className="block" aria-hidden="true">
        <div className="border-t border-gray-200"></div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">Admin name</h3>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2">{data?.adminName}</div>
        </div>
      </div>

      <div className="block" aria-hidden="true">
        <div className="border-t border-gray-200"></div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-sm md:text-base font-medium leading-6 text-gray-700">Admin contact number</h3>
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2">{data?.adminContactNumber}</div>
        </div>
      </div>
    </>
  );
};

export default Register;
