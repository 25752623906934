import moment from 'moment';
import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { showToast } from '../../store/slices/components.slice';
import { getOrder, makePayment, cancelPayment } from '../../store/slices/customer.slice';
import { classNames, statusFormatter } from '../../utils';
import { ModalDiscard } from '../../components/Modal';
import { ModalSubmitReview } from '../../pages/Order/components/SubmitReview';
import { Rate } from 'antd';
import { Link } from 'react-router-dom';
import WhatsApp from '../../assets/images/icons8-whatsapp-48.png';
import CourierIcon from '../../assets/images/icons8-courier-48.png';

import IconGoogleMaps from '../../assets/images/icons8-google-maps.svg';
import IconWaze from '../../assets/images/icons8-waze.svg';
import Image from '../../components/Image';

const OrderInfo = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [order, setOrder] = useState({});
  const [modalOpen, setModulOpen] = useState(false);
  const [modalRatingOpen, setModulRatingOpen] = useState(false);

  useEffect(() => {
    dispatch(getOrder({ id: id }))
      .unwrap()
      .then((result) => {
        const { data } = result;
        setOrder(data);
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  }, []);

  const handlePayment = () => {
    dispatch(
      makePayment({ id: id, returnSuccessUrl: `${window.location.origin}/orders/${id}/payment/success`, returnCancelUrl: `${window.location.origin}/orders/${id}/payment/failed` })
    )
      .unwrap()
      .then(({ data }) => {
        const { paymentLink } = data;
        window.location.replace(paymentLink);
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  };

  const handleCancelPayment = (data) => {
    dispatch(cancelPayment(id))
      .unwrap()
      .then((result) => {
        dispatch(showToast({ show: true, type: 'success', message: `Your payment has been successfully cancel!` }));
        history.push('/orders');
      })
      .catch(({ message }) => {
        setOrder(data);
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  };

  return (
    <>
      <div className="space-y-6 max-w-3xl mx-auto py-6 mb-20 px-4 sm:px-6 lg:px-8 min-h-screen">
        <div className="max-w-3xl mx-auto border rounded-lg bg-white shadow-md ">
          <div className="grid grid-cols-4 gap-4 sm:gap-4 p-6">
            <div className="col-span-4 sm:col-span-2">
              <span className="font-medium text-sm">Store:</span>
              <Link to={`/vendor/${order?.vendorUid}`}>
                <p className="text-sm text-primary-600 hover:text-amber-600 hover:font-semibold">{order?.vendorStoreName}</p>
              </Link>
            </div>
            <div className="col-span-2 sm:col-span-1">
              <span className="font-medium text-sm">Based on:</span>
              <p className="text-sm text-gray-500">
                {order?.vendorStoreCity}, {order?.vendorStoreState}
              </p>
            </div>
            <div className="col-span-2 sm:col-span-1">
              <span className="font-medium text-sm">Distance:</span>
              <p className="text-sm text-gray-500">{order?.distance} Km</p>
            </div>
            <div className="col-span-2 sm:col-span-2">
              <span className="font-medium text-sm">Owner:</span>
              <p className="text-sm text-gray-500">{order?.vendorAdminName}</p>
            </div>
            <div className="col-span-2 sm:col-span-1">
              <span className="font-medium text-sm">Contact:</span>
              <a href={`https://wa.me/${order?.vendorAdminContactNumber}`} target="_blank" rel="noreferrer" className=" text-white hover:font-semibold">
                <p className="flex space-x-2">
                  <Image className="h-5 w-5 rounded-full object-cover" src={WhatsApp} alt="" />
                  <span className="text-sm text-primary-600 hover:text-amber-600">{order?.vendorAdminContactNumber}</span>
                </p>
              </a>
            </div>
          </div>
          <div className="grid grid-cols-4 py-6 px-4 sm:px-6 gap-4 sm:gap-4 border-t">
            <div className="col-span-4 sm:col-span-2">
              <span className="font-medium text-sm">Order ID</span>
              <p className="text-sm text-gray-500">{id}</p>
            </div>
            <div className="col-span-4 sm:col-span-1">
              <span className="font-medium text-sm">{order?.selfPickup ? 'Pickup date' : 'Delivery date'}</span>
              <p className="text-sm text-gray-500">{moment(`${order?.deliverDate} ${order?.deliverTime}`).format('DD MMM YYYY')}</p>
            </div>
            <div className="col-span-4 sm:col-span-1">
              <span className="font-medium text-sm">{order?.selfPickup ? 'Pickup time' : 'Delivery time'} </span>
              <p className="text-sm text-gray-500">{moment(`${order?.deliverDate} ${order?.deliverTime}`).format('hh:mm A')}</p>
            </div>
            <div className="col-span-4 sm:col-span-2">
              <span className="font-medium text-sm">Order Status</span>
              <p className="text-sm text-500" style={{ color: statusFormatter(order.status).color }}>
                {statusFormatter(order?.status)?.text}
              </p>
            </div>
            {order?.statusDetail && (
              <div className="col-span-4 sm:col-span-2">
                <span className="font-medium text-sm">Status Detail</span>
                <p className="text-sm text-500" style={{ color: statusFormatter(order.status).color }}>
                  {statusFormatter(order?.statusDetail)?.text}
                </p>
              </div>
            )}
            <div className="col-span-4 sm:col-span-2">
              {order?.selfPickup ? (
                <>
                  <span className="font-medium text-sm">Pickup location</span>
                  <a
                    href={`https://maps.google.com/?q=${order?.vendorStoreLocationLatLong?.lat},${order?.vendorStoreLocationLatLong?.lon}`}
                    target="_blank"
                    rel="noreferrer"
                    className=" text-white hover:font-semibold"
                  >
                    <p className="flex space-x-2">
                      <Image className="h-5 w-5 rounded-full object-cover" src={IconGoogleMaps} alt="" />
                      <span className="text-sm text-blue-600 hover:text-blue-800 ">Google Map</span>
                    </p>
                  </a>
                  <a
                    href={`https://waze.com/ul?ll=${order?.vendorStoreLocationLatLong?.lat},${order?.vendorStoreLocationLatLong?.lon}&navigate=yes`}
                    target="_blank"
                    rel="noreferrer"
                    className=" text-white hover:font-semibold"
                  >
                    <p className="flex space-x-2">
                      <Image className="h-5 w-5 rounded-full object-cover" src={IconWaze} alt="" />
                      <span className="text-sm text-blue-600 hover:text-blue-800 ">Waze</span>
                    </p>
                  </a>
                  <p className="text-sm text-gray-500">{`${order?.vendorStoreLocationLatLong?.lat},${order?.vendorStoreLocationLatLong?.lon}`}</p>
                </>
              ) : (
                <>
                  <span className="font-medium text-sm">Delivery Address</span>
                  <p className="text-sm text-gray-500">{`${order?.address1}, ${order?.address2}, ${order?.postalCode} ${order?.city}, ${order?.state}`}</p>
                </>
              )}
            </div>
            {order?.lalamoveOrder?.shareLink && (
              <div className="col-span-4 sm:col-span-1">
                <span className="font-medium text-sm">Delivery Partner</span>
                <a href={`${order?.lalamoveOrder?.shareLink}`} target="_blank" rel="noreferrer" className=" text-white hover:font-semibold">
                  <p className="flex space-x-2">
                    <Image className="h-5 w-5 rounded-full object-cover" src={CourierIcon} alt="" />
                    <span className="text-sm text-primary-600 hover:text-amber-600">Lalamove</span>
                  </p>
                </a>
              </div>
            )}
          </div>
          <div className={classNames(order?.status === 'DELIVERED' || order?.status === 'COMPLETED' ? 'block' : 'hidden')}>
            <div className="border-t border-gray-200 py-6 px-4 sm:px-6 space-y-4 ">
              <div className="grid grid-cols-4  sm:px-6 gap-4 sm:gap-4 ">
                <div className="col-span-4 sm:col-span-2 ">
                  <p className="font-medium">Rate</p>
                  <Rate disabled value={order.reviewRating} />
                </div>
                <div className="col-span-4 sm:col-span-2">
                  <p className="font-medium">Comment</p>
                  <p className="mt-1 text-gray-500">{order.reviewComment || ''}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="border-t flex-1 py-6 overflow-y-auto px-4 sm:px-6">
            <div>
              <div className="flow-root">
                <ul role="list" className="-my-6 divide-y divide-gray-200">
                  {order?.products &&
                    order?.products.map((product, index) => (
                      <li key={index} className="py-6 flex">
                        <div className="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
                          <img src={product.info.picture.path.s} alt={product.info.name} className="w-full h-full object-center object-cover" />
                        </div>

                        <div className="ml-4 flex-1 flex flex-col">
                          <div>
                            <div className="flex justify-between text-base font-medium text-gray-900">
                              <h3>{product.info.name}</h3>
                              <p className="ml-4">RM {Number(product.price * product.quantity).toFixed(2)}</p>
                            </div>
                            <p className="mt-1 text-sm text-gray-500">
                              Qty {product.quantity} x RM {product.price.toFixed(2)}
                            </p>
                          </div>
                          <div className="flex-1 flex items-end justify-between text-sm">
                            <p className="text-gray-900 font-medium">
                              Remark: <span className="text-gray-500 font-normal">{product.remark || 'Not provided'}</span>
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                  {order?.discountQuantity > 0 && (
                    <li className="py-6 flex">
                      <div className="ml-4 flex-1 flex flex-col">
                        <div>
                          <div className="flex justify-between text-base font-medium text-gray-900">
                            <h3>{order?.discountDescription}</h3>
                            <p className="ml-4">- RM {order?.discountQuantity.toFixed(2)}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                  )}
                  {(order?.deliveryPrice || order?.deliveryPriceTier?.price) && (
                    <li className="py-6 flex">
                      <div className="ml-4 flex-1 flex flex-col">
                        <div>
                          <div className="flex justify-between text-base font-medium text-gray-900">
                            <h3>Delivery Charge</h3>
                            <p className="ml-4">RM {order?.deliveryPrice.toFixed(2) || order?.deliveryPriceTier?.price.toFixed(2)}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200 py-6 px-4 sm:px-6 space-y-4">
            <div>
              <div className="text-sm text-gray-900">
                <p className="font-medium">Special instruction:</p>
                <p className="mt-1 text-gray-500">{order?.orderRemark || 'Not provided'}</p>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200 py-6 px-4 sm:px-6 space-y-4">
            <div>
              <div className="flex justify-between text-xl font-medium text-gray-900">
                <p>Total</p>
                <p className="font-bold">RM {order?.totalPrice?.toFixed(2)}</p>
              </div>
            </div>
          </div>
          <div className={classNames(['DELIVERED', 'PICKED_UP'].includes(order?.status) ? 'block' : 'hidden', 'pb-4 sm:pb-6 px-4 sm:px-6')}>
            <button
              type="button"
              onClick={() => setModulRatingOpen(true)}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              Received Order
            </button>
          </div>
          <div className={classNames(order.status == 'COMPLETED' && !order.reviewRating ? 'block' : 'hidden', 'pb-4 sm:pb-6 px-4 sm:px-6')}>
            <button
              type="button"
              onClick={() => setModulRatingOpen(true)}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              Review now
            </button>
          </div>
          <ModalSubmitReview id={id} show={modalRatingOpen} closeAction={() => setModulRatingOpen(false)} />
          <div className={classNames(!order?.paymentStatus || order?.paymentStatus === 'open' ? 'block' : 'hidden', 'pb-4 sm:pb-6 px-4 sm:px-6')}>
            <button
              type="button"
              onClick={handlePayment}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              Pay RM {order?.totalPrice?.toFixed(2)}
            </button>
          </div>
          <div className={classNames(!order?.paymentStatus || order?.paymentStatus === 'open' ? 'block' : 'hidden', 'pb-4 sm:pb-6 px-4 sm:px-6')}>
            <button
              type="button"
              onClick={() => setModulOpen(true)}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Cancel this payment
            </button>
            <ModalDiscard
              show={modalOpen}
              title="Alert!"
              description="Are you sure want to cancel this payment?"
              // hide={() => this.setState({ showModalForgotPassword: false })}
              closeable={false}
              confirmText="Continue cancel"
              cancelText="back"
              confirmAction={handleCancelPayment}
              closeAction={() => setModulOpen(false)}
            >
              <div className="w-full">
                <div className="px-4 py-5 space-y-6 sm:p-6">
                  <p>This action will be cancelling this order</p>
                </div>
              </div>
            </ModalDiscard>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderInfo;
