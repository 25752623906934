import { useEffect, useRef, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { ProtectedRoute, PublicRoute } from './components/Routes';
import { Modal } from './components/Modal';
import { Form, Input } from './components/Form';
import Toast from './components/Toast';

import Callback from './pages/Callback';
import CallbackRedirectAccess from './pages/CallbackRedirectAccess';
import Home from './pages/Public/Home';
import VendorProfile from './pages/Public/Vendor/Profile';
import Store from './pages/Store';
import Addresses from './pages/Addresses';

import { getUser, saveAddresses, saveDeliverToAddress, saveHasSetupStore, saveProfilePicture, updateUser } from './store/slices/user.slice';
import { showToast } from './store/slices/components.slice';

import tokenService from './services/token.service';
import Address from './pages/Addresses/Address';
import Order from './pages/Order';
import UserProfile from './pages/User';
import { getVendor } from './store/slices/vendor.slice';
import { MailIcon } from '@iconicicons/react';
import facebook from './assets/images/icons8-facebook.svg';
import linkedin from './assets/images/icons8-linkedin-circled.svg';
import instagram from './assets/images/icons8-instagram.svg';
import twitter from './assets/images/icons8-twitter-circled.svg';
import Image from './components/Image';
import Layout from './components/Layout';
import AboutUs from './pages/Public/AboutUs';
import PrivacyPolicy from './pages/Public/PrivacyPolicy';
import TermOfUse from './pages/Public/TermOfUse';
import { Link } from 'react-router-dom';

import { goToTop } from './utils';

const App = () => {
  const { components, userApi } = useSelector((state) => state);
  const toast = components.toast;
  const dispatch = useDispatch();
  const formNode = useRef();
  const location = useLocation();

  const [firstTimeModal, setFirstTimeModal] = useState(false);
  const [vendorData, setVendorData] = useState();
  const [userData, setUserData] = useState();

  useEffect(() => {
    if (tokenService.getLocalAccessToken()) {
      handleGetUser();
      handleGetVendor();
    }
    goToTop();
  }, [location]);

  window.onunload = function () {
    sessionStorage.clear();
  };

  const handleGetUser = async () => {
    if (tokenService.getLocalAccessToken()) {
      const user = await JSON.parse(sessionStorage.getItem('user'));
      if (user?.email) {
        setUserData(user);
        if (!user.name || !user.phoneNumber) {
          setFirstTimeModal(true);
        } else {
          dispatch(saveDeliverToAddress(_.find(user.addresses, { isDefault: true })));
          dispatch(saveAddresses(user.addresses));
          dispatch(saveProfilePicture(user.profilePic));
        }
      } else {
        dispatch(getUser())
          .unwrap()
          .then(({ data }) => {
            setUserData(data);
            if (!data.name || !data.phoneNumber) {
              setFirstTimeModal(true);
            } else {
              dispatch(saveDeliverToAddress(_.find(data.addresses, { isDefault: true })));
              dispatch(saveAddresses(data.addresses));
              dispatch(saveProfilePicture(data.profilePic));
            }
          });
      }
    }
  };

  const handleGetVendor = async () => {
    const vendor = await JSON.parse(sessionStorage.getItem('vendor'));
    if (vendor?.uid) {
      setVendorData({ data: vendor });
      const { status } = vendor;
      if (['REGISTER'].includes(status)) {
        dispatch(saveHasSetupStore(false));
      }
    } else {
      dispatch(getVendor())
        .unwrap()
        .then((result) => {
          setVendorData(result);
          const { status } = result.data;
          if (['REGISTER'].includes(status)) {
            dispatch(saveHasSetupStore(false));
          }
        });
    }
  };

  const handleSubmit = (data) => {
    dispatch(updateUser(data))
      .unwrap()
      .then(({ message }) => {
        dispatch(showToast({ show: true, type: 'success', message: message }));
        setFirstTimeModal(false);
        handleGetUser();
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  };

  const [tooltipAddressValue, setTooltipAddressValue] = useState(true);

  const changeTooltipAddressValue = (data) => {
    if (data != null) setTooltipAddressValue(data);
  };

  return (
    <>
      <Layout.Public path={location.pathname} vendorData={vendorData} tooltipAddressValue={tooltipAddressValue}>
        <Switch>
          <PublicRoute exact path="/" component={Home} tooltipAddressValue={tooltipAddressValue} changeTooltipAddressValue={changeTooltipAddressValue} />
          <PublicRoute exact path="/vendor/:id" component={VendorProfile} tooltipAddressValue={tooltipAddressValue} changeTooltipAddressValue={changeTooltipAddressValue} />
          <PublicRoute exact path="/callback" component={Callback} />
          <PublicRoute exact path="/callback-redirect-access" component={CallbackRedirectAccess} />
          <PublicRoute path="/about-us" component={AboutUs} />
          <PublicRoute path="/privacy-policy" component={PrivacyPolicy} />
          <PublicRoute path="/term-of-use" component={TermOfUse} />
          <ProtectedRoute exact path="/profile" component={UserProfile} userData={userData} />
          <ProtectedRoute exact path="/addresses" component={Addresses} userData={userData} />
          <ProtectedRoute exact path="/addresses/new" component={Address} userData={userData} />
          <ProtectedRoute exact path="/addresses/edit/:id" component={Address} userData={userData} />
          <ProtectedRoute exact path="/orders" component={Order.List} />
          <ProtectedRoute exact path="/orders/:id" component={Order.Info} />
          <ProtectedRoute exact path="/orders/:id/payment/success" component={Order.PaymentSuccess} />
          <ProtectedRoute exact path="/orders/:id/payment/failed" component={Order.PaymentFailed} />
          <ProtectedRoute exact path="/orders/vendor/:id" component={Order} />
          <ProtectedRoute exact path="/store/register" component={Store.Register} />
          <ProtectedRoute path="/store" component={Store} vendorData={vendorData} />
          <Route component={NotFound} />
        </Switch>
      </Layout.Public>
      <Toast show={toast.show} type={toast.type} subject={toast.subject} message={toast.message} errors={toast.errors} />
      <Modal
        show={firstTimeModal}
        title="Complete your account"
        description="You're required to complete your account before proceed"
        // hide={() => this.setState({ showModalForgotPassword: false })}
        loading={userApi.loading}
        closeable={false}
        confirmText="Submit"
        confirmAction={() => formNode.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))}
      >
        <div className="w-full">
          <Form onSubmit={handleSubmit} setRef={formNode}>
            <div className="px-4 py-5 space-y-6 sm:p-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6">
                  <Input.Text name="name" label="Name" placeholder="Your name" disabled={userApi.loading} rules={[{ required: true, message: 'This field is required' }]} />
                </div>
                <div className="col-span-6">
                  <Input.Phone
                    name="phoneNumber"
                    label="Phone number"
                    placeholder="e.g 60123456789"
                    disabled={userApi.loading}
                    rules={[{ required: true, message: 'This field is required' }]}
                  />
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Modal>

      <nav className="bg-primary-600">
        <div className="max-w-7xl mx-auto px-2  sm:px-6 lg:px-8">
          <div className=" sm:flex items-center justify-between h-32 sm:h-16 overflow-x-auto">
            <div className="flex-shrink-0 flex items-center justify-center">
              {/* <a className="ml-4 text-s font-logo text-white mt-3 sm:mt-0 underline" href={`https://nubes.my/privacy-policy.html`} target="_blank">
                Privacy Policy
              </a> */}
              <Link to={'/term-of-use'} className="ml-4 text-s font-logo text-white mt-3 sm:mt-0 underline">
                Terms Of Use
              </Link>
              <Link to={'/privacy-policy'} className="ml-4 text-s font-logo text-white mt-3 sm:mt-0 underline">
                Privacy Policy
              </Link>
              <Link to={'/about-us'} className="ml-4 text-s font-logo text-white mt-3 sm:mt-0 underline">
                About Us
              </Link>
            </div>
            <div className="flex-shrink-0 sm:flex items-center space-x-4 justify-center ">
              <div className="justify-center hidden sm:block">
                <p className="ml-4 text-s text-white uppercase ">© 2022 Nubescom Sdn. Bhd. All rights reserved. </p>
              </div>
              <div className=" sm:hidden">
                <p className="ml-4 text-s text-white uppercase text-center">© 2022 Nubescom Sdn. Bhd.</p>
                <p className="ml-4 text-s text-white uppercase text-center">All rights reserved. </p>
              </div>
              <div className="flex justify-center">
                <a href={`https://www.facebook.com/nubes.my`} target="_blank" rel="noreferrer" className=" text-white hover:font-semibold">
                  <p className="flex space-x-2">
                    <Image className=" object-cover" src={facebook} alt="" />
                  </p>
                </a>
                <a href={`https://www.linkedin.com/company/nubesmy`} target="_blank" rel="noreferrer" className=" text-white hover:font-semibold">
                  <p className="flex space-x-2">
                    <Image className="  object-cover" src={linkedin} alt="" />
                  </p>
                </a>
                <a href={`https://twitter.com/nubesmy`} target="_blank" rel="noreferrer" className=" text-white hover:font-semibold">
                  <p className="flex space-x-2">
                    <Image className=" object-cover" src={twitter} alt="" />
                  </p>
                </a>
                <a href={`https://www.instagram.com/nubes.my`} target="_blank" rel="noreferrer" className=" text-white hover:font-semibold">
                  <p className="flex space-x-2">
                    <Image className=" object-cover" src={instagram} alt="" />
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

const NotFound = () => {
  return (
    <section id="not-found" className="flex items-center justify-center relative bg-white h-screen">
      <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-7xl">
        <p className="text-base font-semibold tracking-wider text-primary-500 uppercase">404 Error</p>
        <h2 className="mt-2 text-3xl sm:text-5xl font-extrabold text-primary-600 tracking-tight">Not found.</h2>
        <p className="my-5 text-base text-gray-500 sm:leading-10 sm:text-xl lg:text-lg xl:text-xl">Sorry, we couldn't find the page you looking for.</p>
        <a href="/" className="text-base text-primary-500 hover:text-primary-600 font-semibold sm:text-xl lg:text-lg xl:text-xl">
          Go back home →
        </a>
      </div>
    </section>
  );
};

export default App;
