import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Form, Input } from '../../components/Form';
import Image from '../../components/Image';
import ReadMore from '../../components/ReadMore';
import { listProducts, listProductsByLocation } from '../../store/slices/customer.slice';
import { classNames, goToTop } from '../../utils';
import _ from 'lodash';
import tokenService from '../../services/token.service';
import Tooltip from '../../components/Tooltip';
import { saveCurrentCoordinate, saveDeliverToAddress } from '../../store/slices/user.slice';
import LoadingScreen from '../../components/LoadingScreen';
import { ModalLogin } from '../../components/Modal';

import MerdekaPromoFrame from '../../assets/images/merdeka-promo-frame.png';
import DiscountQuantityFrame from '../../assets/images/discount-quantity-frame.png';
import HomeBanner from '../../assets/images/home-banner.png';

const Home = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { customerApi, userApi } = useSelector((state) => state);

  const [loaded, setLoaded] = useState(false);
  const [productList, setProductList] = useState([]);
  const [productFiltered, setProductFiltered] = useState([]);
  const [displayTooltipLocation, setDisplayTooltipLocation] = useState(true);
  const [modalOpenNeedLogin, setModalOpenNeedLogin] = useState(false);
  const changeTooltipAddressValue = props.changeTooltipAddressValue;

  useEffect(() => {
    const localAccessToken = tokenService.getLocalAccessToken();
    if (!_.isEmpty(userApi.deliverToAddress)) {
      getProductListByLocation(userApi.deliverToAddress.location);
    } else {
      if (userApi.currentCoordinate?.lat) {
        getProductListByLocation(userApi.currentCoordinate);
      } else if (!localAccessToken) {
        getProductList();
      } else if (userApi.deliverToAddress === undefined) {
        getProductList();
      }
    }
    if (userApi?.payload !== null && userApi?.addresses?.length == 0) {
      changeTooltipAddressValue(true);
    }
  }, [userApi.deliverToAddress]);

  const getProductList = () => {
    if (!loaded) {
      setLoaded(true);
      dispatch(listProducts())
        .unwrap()
        .then(({ data }) => {
          setProductList(data.products);
          setProductFiltered(data.products);
          setLoaded(false);
        });
    }
  };

  const getProductListByLocation = (coordinate) => {
    if (coordinate['lng']) {
      coordinate['lon'] = coordinate['lng'];
      delete coordinate['lng'];
    }
    if (!loaded) {
      setLoaded(true);
      dispatch(saveCurrentCoordinate(coordinate));
      dispatch(listProductsByLocation(JSON.stringify(coordinate)))
        .unwrap()
        .then(({ data }) => {
          setProductList(data.products);
          setProductFiltered(data.products);
          setLoaded(false);
        });
    }
  };

  function searchProduct(e) {
    const value = e.target.value;
    const cleanString = value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ' ').toLowerCase();
    let searchWords = cleanString.split(' ') || [];
    searchWords = searchWords.filter((word) => word !== '');
    if (searchWords.length > 0) {
      const filterProduct = productList.filter((product) => {
        let isMatch = false;
        for (const word of searchWords) {
          if (product.name.toLowerCase().includes(word) || product.vendor.storeName.toLowerCase().includes(word)) {
            isMatch = true;
            break;
          }
        }
        return isMatch;
      });
      setProductFiltered(filterProduct);
    } else {
      setProductFiltered(productList);
    }
  }

  async function handleActionClickLocation() {
    goToTop();
    setDisplayTooltipLocation(false);
    await setTimeout(() => setDisplayTooltipLocation(true), 1000);
    await setTimeout(() => setDisplayTooltipLocation(false), 1500);
    await setTimeout(() => setDisplayTooltipLocation(true), 1800);
  }

  async function handleActionClickAddress() {
    goToTop();
    changeTooltipAddressValue(false);
    await setTimeout(() => changeTooltipAddressValue(true), 1000);
    await setTimeout(() => changeTooltipAddressValue(false), 1500);
    await setTimeout(() => changeTooltipAddressValue(true), 1800);
  }

  return (
    <>
      <LoadingScreen show={userApi.loading || customerApi.loading} />
      <div className="max-w-7xl mx-auto h-[10rem] md:h-[12rem]">
        <img className="w-full h-full object-center object-cover" alt="HomeBanner" src={HomeBanner} />
      </div>
      <div className="max-w-7xl mx-auto px-6 md:px-8 py-8 md:py-16">
        {!tokenService.getToken() && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
            <div className="col-span-1 md:col-span-2 bg-white ring-1 ring-black ring-opacity-5 rounded-lg shadow-md">
              <div className="py-8 px-4 sm:px-6">
                <h2 className="mb-4 text-2xl font-extrabold tracking-tight text-gray-900">Search products by location</h2>
                <Form defaultValues={{ storeLocationLatLong: userApi.currentCoordinate }}>
                  <Input.GoogleMap
                    name="storeLocationLatLong"
                    disabled={customerApi.loading}
                    retrieveValue={(data) => getProductListByLocation(data)}
                    tooltipComponent={
                      (!tokenService.getToken() || !userApi?.deliverToAddress?.name) &&
                      _.isEmpty(userApi.currentCoordinate) &&
                      displayTooltipLocation && (
                        <div className="absolute bottom-full left-1/2 mb-1 pb-1 -translate-x-1/2">
                          <Tooltip bgColor="bg-amber-500/95 text-white" chevronColor="text-amber-500">
                            Click here to check product availability
                          </Tooltip>
                        </div>
                      )
                    }
                  />
                </Form>
              </div>
            </div>
          </div>
        )}

        <div className="bg-white ring-1 ring-black ring-opacity-5 rounded-lg shadow-md">
          <div className="max-w-2xl mx-auto py-8 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">Products</h2>
            <input
              className={`mt-1 block w-full shadow-sm sm:text-sm rounded-md disabled:opacity-50 disabled:bg-gray-200`}
              type="text"
              placeholder="Search"
              maxLength={40}
              onChange={searchProduct}
            />
            <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
              {productFiltered.length > 0 ? (
                productFiltered.map((product) => (
                  <div key={product?.uid}>
                    <div className="group">
                      <div className="w-full aspect-w-1 aspect-h-1 bg-white rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-7">
                        {product?.vendor?.storeDiscountQuantityEnable ? (
                          <div class="w-full h-full object-center object-cover rounded-lg">
                            <img src={product?.picture?.path?.m} alt={product?.uid} className="h-full" />
                            <img src={DiscountQuantityFrame} alt={'Discount Bundle'} className="relative bottom-full h-full w-full" />
                          </div>
                        ) : (
                          <Image src={product?.picture?.path?.m} alt={product?.uid} className="w-full h-full object-center object-cover rounded-lg" />
                        )}
                      </div>
                    </div>
                    <div className="mt-4 flex items-start">
                      <div>
                        <span className="text-base font-medium text-gray-700">
                          {product?.name}
                          <p className="text-sm font-normal">
                            by{' '}
                            <Link to={`/vendor/${product?.vendor?.username || product?.vendor?.uid}`} className="underline text-gray-500 hover:text-amber-600">
                              {product?.vendor?.storeName}
                            </Link>
                          </p>
                        </span>
                        <span className="text-base font-medium text-gray-500">
                          <ReadMore maxChar={40}>{product?.description}</ReadMore>
                        </span>
                        <div className="flex flex-row">
                          <p className="mt-4 text-lg font-semibold text-gray-900">RM {product?.price?.toFixed(2)}</p>
                          {product?.display_price_dummy && (
                            <p className="mt-4 text-sm line-through font-light text-orange-600 ml-2 relative top-1">RM {product?.display_price_dummy?.toFixed(2)}</p>
                          )}
                        </div>
                        {product?.distance && product?.distance > product?.availabilityRadiusKm ? (
                          <span className="ml-auto mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-gray-100 text-gray-600 uppercase">Out Of Range</span>
                        ) : (
                          <>
                            {product?.vendor?.deliveryEnable ? (
                              <span className="ml-auto mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-primary-100 text-primary-600 uppercase">Delivery</span>
                            ) : (
                              <span className="ml-auto mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-gray-100 text-gray-600 uppercase">
                                Delivery Unavailable
                              </span>
                            )}
                          </>
                        )}
                        {product?.vendor?.selfPickupEnable ? (
                          <span className="ml-auto mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-primary-100 text-primary-600 uppercase">Self Pickup</span>
                        ) : (
                          <span className="ml-auto mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-gray-100 text-gray-600 uppercase">
                            Self Pickup Unavailable
                          </span>
                        )}
                      </div>
                      {product?.distance !== (null || undefined) ? (
                        product?.distance > product?.availabilityRadiusKm ? (
                          <>
                            <span className="ml-auto mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-gray-100 text-gray-600 uppercase">
                              {product?.distance?.toFixed(2)} KM
                            </span>
                          </>
                        ) : (
                          <span className="ml-auto mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-primary-100 text-primary-600 uppercase">
                            {product?.distance?.toFixed(2)} KM
                          </span>
                        )
                      ) : null}
                    </div>
                    {userApi?.payload !== null ? (
                      product?.availabilityRadiusKm && product?.distance !== (null || undefined) ? (
                        product?.isAvailable ? (
                          <>
                            {product?.distance && product?.distance > product?.availabilityRadiusKm && !product?.vendor?.selfPickupEnable ? (
                              <button
                                type="button"
                                disabled={product?.isAvailable}
                                className="cursor-not-allowed disabled:bg-opacity-30 bg-gray-200 text-gray-500 mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md focus:outline-none"
                              >
                                Unavailable
                              </button>
                            ) : (
                              <Link
                                to={`/orders/vendor/${product?.vendor?.uid}?product_selected=${product?.uid}`}
                                className="bg-amber-500 text-white hover:text-amber-100 hover:bg-amber-600 mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md focus:outline-none"
                              >
                                Place order
                              </Link>
                            )}
                          </>
                        ) : (
                          <button
                            type="button"
                            disabled={product?.isAvailable}
                            className="cursor-not-allowed disabled:bg-opacity-30 bg-gray-200 text-gray-500 mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md focus:outline-none"
                          >
                            Not available
                          </button>
                        )
                      ) : (
                        <button
                          type="button"
                          onClick={handleActionClickAddress}
                          className="bg-amber-500 text-white hover:text-amber-100 hover:bg-amber-600 mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md focus:outline-none"
                        >
                          Please add address first
                        </button>
                      )
                    ) : product?.availabilityRadiusKm && product?.distance !== (null || undefined) ? (
                      product?.isAvailable ? (
                        <>
                          {product?.distance && product?.distance > product?.availabilityRadiusKm && !product?.vendor?.selfPickupEnable ? (
                            <button
                              type="button"
                              disabled={true}
                              className="cursor-not-allowed disabled:bg-opacity-30 bg-gray-200 text-gray-500 mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md focus:outline-none"
                            >
                              Unvailable
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={() => setModalOpenNeedLogin(true)}
                              className="bg-amber-500 text-white hover:text-amber-100 hover:bg-amber-600 mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md focus:outline-none"
                            >
                              Place order
                            </button>
                          )}
                        </>
                      ) : (
                        <button
                          type="button"
                          disabled={product?.isAvailable}
                          className="cursor-not-allowed disabled:bg-opacity-30 bg-gray-200 text-gray-500 mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md focus:outline-none"
                        >
                          Not available
                        </button>
                      )
                    ) : (
                      <button
                        type="button"
                        onClick={handleActionClickLocation}
                        className="bg-amber-500 text-white hover:text-amber-100 hover:bg-amber-600 mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md focus:outline-none"
                      >
                        Check Availability
                      </button>
                    )}
                  </div>
                ))
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalLogin
        show={modalOpenNeedLogin}
        title="Alert!"
        description="Placing an Order?"
        // hide={() => this.setState({ showModalForgotPassword: false })}
        closeable={false}
        confirmText="Register/Log In"
        cancelText="Cancel"
        closeAction={() => setModalOpenNeedLogin(false)}
      >
        <div className="w-full">
          <div className="px-4 py-5 space-y-6 sm:p-6">
            <p>You need to register/log in first to make an order.</p>
          </div>
        </div>
      </ModalLogin>
    </>
  );
};

export default Home;
